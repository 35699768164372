<script>

import MainSection from "~/components/Shared/MainSection";
import HeaderSection from "~/components/Shared/Header/HeaderSection";
import FooterSection from "~/components/Shared/Footer/FooterSection";
import BaseTitleWrapper from "~/components/Base/BaseTitleWrapper";
import BaseTitle from "~/components/Base/BaseTitle";
import CardWrapper from "~/components/Shared/CardWrapper";
import CardBody from "~/components/Shared/CardBody";
import BaseButton from "~/components/Base/BaseButton";
import HomeProfileSection from "~/components/Home/HomeProfileSection";
import HomeNotificationSection from "~/components/Home/HomeNotificationSection";
import HomeMainSection from "~/components/Home/HomeMainSection";
import BasePageContentWrapper from "~/components/Base/BasePageContentWrapper";
import { ref } from "vue"
import dictionary from "~/utils/dictionaryUtils";
import { useStore } from "vuex"
import { useToast } from "vue-toastification";

export default {
  name: 'home-page',

  components: {
    BasePageContentWrapper,
    MainSection,
    HeaderSection,
    BaseTitleWrapper,
    BaseTitle,
    CardWrapper,
    CardBody,
    BaseButton,
    FooterSection,
    HomeProfileSection,
    HomeNotificationSection,
    HomeMainSection
  },

  setup() {
    const pageLoading = ref(true)
    const store = useStore()
    const toast = useToast()

    store.dispatch('getProfile').then( (response) => {

      if (response.error) {
        toast.error(response.error || dictionary.ERROR.GENERIC, {
          timeout: false
        })
      }

    })

    setTimeout(() => {
      pageLoading.value = false
    }, 100)

    return {
      pageLoading
    }
  }

}
</script>

<template>
  <header-section page="home"/>
  <base-page-content-wrapper :page-loading="pageLoading">
    <main-section>
      <div class="container">
        <div class="row">
          <div class="col-12 col-md-4 col-xl-4 col-xxl-3 mb-17 mb-md-0">
            <base-title-wrapper>
              <base-title text="My Profile"/>
            </base-title-wrapper>
            <card-wrapper class="mb-12">
              <card-body class="border-radius-xl pa-6 pa-lg-10">
                <home-profile-section/>
              </card-body>
            </card-wrapper>
            <card-wrapper class="mb-12">
              <card-body class="border-radius-xl pa-5 pa-xl-10">
                <home-notification-section/>
              </card-body>
            </card-wrapper>
          </div>
          <div class="col-12 col-md-8 col-xl-8 col-xxl-9">
            <base-title-wrapper>
              <base-title text="My Bikes"/>
              <base-button
                value="Add a bike"
                icon-class="fas mr-4 mt--1 mb--1 fa-plus"
                include-base-classes
              />
            </base-title-wrapper>
            <home-main-section/>
<!--            <div class="slider-xs">-->
<!--              -->
<!--            </div>-->
          </div>
        </div>
      </div>
    </main-section>
  </base-page-content-wrapper>
  <footer-section/>
</template>
