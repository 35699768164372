<script>
import BaseButton from "~/components/Base/BaseButton";
import { useStore } from "vuex"
import { computed } from "vue";
import BaseLink from "~/components/Base/BaseLink";

export default {
  name: 'home-profile-section',

  components: {
    BaseLink,
    BaseButton
  },

  setup() {
    const store = useStore(),
      state = computed(() => ({
        fullName: store.getters.userFullName,
        bioSnippet: store.getters.userBioSnippet,
        avatar: store.getters.userAvatar
      }))

    return {
      state
    }
  }
}
</script>

<template>
  <div
    class="d-grid d-md-block d-lg-grid grid_auto_1fr grid-col-gap-1rem mb-12 text-left text-md-center text-lg-left pt-5 pt-md-0">
    <div class="mb-md-6 mb-lg-0">
      <img :src="state.avatar" class="circle img-object-fit w-19 h-19 w-md-21 h-md-21 border">
    </div>
    <div>
      <h3
        class="font-16 font-lg-18 mb-3 d-flex align-items-center justify-content-start justify-content-md-center justify-content-lg-start">
        {{ state.fullName }}
        <span class="circle w-5 h-5 bg-lima d-block border-white ml-4 p-relative t--4"></span>
      </h3>
      <p class="c-gray-chateau font-14 font-md-12 font-lg-14 mb-0">
        {{ state.bioSnippet || '' }}
      </p>
    </div>
  </div>
  <!-- -->
  <base-link to="/profile">
    <base-button
      include-base-classes
      value="Visit profile page"
      class="w-100"
    />
  </base-link>
</template>
