<script>

import BikeCard from "~/components/Shared/BikeCard/BikeCard";
import BaseCarouselIndicator from "~/components/Base/BaseCarouselIndicator";
import { reactive, ref } from "vue"

export default {
  name: "home-main-section",

  components: {
    BaseCarouselIndicator,
    BikeCard
  },

  setup() {

    const bikeCards = reactive([1, 2, 3, 4, 5])
    const cardRefs = ref([])
    const currentIdx = ref(0)
    const wrapperRef = ref(null)

    function handleScroll() {
      const windowWidth = window.innerWidth
      const byLeftOffset = cardRefs.value
        .map( (cr, idx) => {
          const _bounds = cr.$el.getBoundingClientRect()
          return {
            index: idx,
            passedHalf: _bounds.left < windowWidth / 2,
            left: _bounds.left
          }
        })
        .filter( cr => cr.passedHalf)
        .sort((a,b) => a.left > b.left ? -1 : b.left > a.left ? 1 : 0)

      currentIdx.value = byLeftOffset[0].index
    }

    function handleBulletClick(idx) {
      const cardBounds = cardRefs.value[idx].$el.getBoundingClientRect()
      const cardOffset = cardBounds.left
      const desiredOffset = (window.innerWidth / 2) - cardBounds.width / 2

      if (cardOffset > desiredOffset) {
        wrapperRef.value.scrollLeft += cardOffset - desiredOffset
      } else {
        wrapperRef.value.scrollLeft -= desiredOffset - cardOffset
      }
    }

    return {
      cardRefs,
      bikeCards,
      currentIdx,
      wrapperRef,
      handleScroll,
      handleBulletClick
    }
  }
};
</script>

<template>
  <div class="slider-xs">
    <div
      class="d-grid grid-row-gap-1_5rem grid-col-gap-1_5rem grid-col-md-2 grid-col-xl-2 grid-col-xxl-3"
      @scroll="handleScroll"
      ref="wrapperRef"
    >
      <bike-card
        v-for="(bikeCard, idx) in bikeCards"
        :key="`bike-card-${idx}`"
        :ref="el => { cardRefs[idx] = el }"
        :id="`ex-${idx}`"
      />
    </div>
  </div>
  <base-carousel-indicator
    wrapperClasses="carousel-indicators sd-flex d-md-none inverse mt-12 mb-0"
    :currentIdx="currentIdx"
    :slide-count="bikeCards.length"
    interactive
    @bullet-click="handleBulletClick"
  />
</template>


