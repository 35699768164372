<script>
import CardWrapper from "~/components/Shared/CardWrapper";
import CardBody from "~/components/Shared/CardBody";
import CardFooter from "~/components/Shared/CardFooter";
import BikeCardSocialStat from "~/components/Shared/BikeCard/BikeCardSocialStat";
import BaseButton from "~/components/Base/BaseButton";
// import BikeCardImageSteps from "~/components/Shared/BikeCard/BikeCardImageSteps";
import ItemDetails from "~/components/Shared/ItemDetails/ItemDetails";
import ImageSlider from "~/components/Shared/ImageSlider/ImageSlider";

export default {
  name: "bike-card",

  components: {
    ImageSlider,
    CardWrapper,
    CardBody,
    CardFooter,
    BikeCardSocialStat,
    BaseButton,
    // BikeCardImageSteps,
    ItemDetails
  },

  setup() {
    let slides = [
      '/img/dummy/biker.jpg',
      '/img/dummy/biker.jpg',
      '/img/dummy/biker.jpg',
      '/img/dummy/biker.jpg'
    ]

    return {
      slides
    }
  }
};
</script>

<template>
  <div>
    <div class="border-radius-xl shadow d-flex flex-column">
      <card-wrapper>
        <card-body
          class="border-radius-top-xl pa-0 embed-responsive embed-responsive-4by3 border-radius-bottom-none overlay-bg-40"
        >
<!--          <img src="/img/dummy/biker.jpg" class="embed-responsive-item img-object-fit">-->
          <image-slider
            class="embed-responsive-item"
            inner-extra-class="h-100"
            :slides="slides"
            :show-buttons="false"
            :show-indicator="false"
            indicator-style="bike-card"
            :with-border-radius="false"
          />
          <base-button
            class="btn-primary btn-def py-3 px-4 font-12 font-md-10 font-lg-12 border-radius text-uppercase d-inline-flex align-items-center justify-content-center p-absolute t-4 r-4 z-index-1 btn-shadow"
            icon-class="fas fa-pen font-10 mr-4"
            value="Edit bike"
          />
<!--          <bike-card-image-steps />-->
        </card-body>
      </card-wrapper>
      <card-footer class="border-radius-bottom-xl d-flex flex-column justify-content-start flex-grow-1">
        <div class="d-grid grid_auto_1fr align-items-center">
          <item-details class="px-6 px-lg-10 py-9 py-lg-12 flex-grow-1"/>
        </div>
        <div class="d-grid grid-col-2 border-top text-center font-14 font-md-11 font-lg-14 fw-600">
          <bike-card-social-stat
            class="border-right c-primary"
            child-class="py-6 pl-6"
            icon="fa-thumbs-up"
            icon-class="fas mr-3"
            text=" 12 Likes "
          />
          <bike-card-social-stat
            class="c-gray-chateau"
            child-class="py-6 pr-6"
            icon="fa-eye"
            icon-class="fas mr-3"
            text=" 159 Views "
          />
        </div>
      </card-footer>
    </div>
  </div>
</template>

