<script>

import EditNotification from "~/components/Shared/EditNotification";

export default {
  name: "home-notification-section",

  components: { EditNotification }
};
</script>

<template>
  <edit-notification
    icon-class="fa-tachometer"
    class="mb-15 mb-md-10 mb-lg-15"
  />
  <edit-notification
    icon-class="fa-fingerprint"
    class="mb-15 mb-md-10 mb-lg-15"
  />
  <edit-notification
    icon-class="fa-rocket"
    class="mb-0"
  />
</template>


