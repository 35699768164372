<script>
export default {
  name: "bike-social-stat",

  props: {
    icon: {
      type: String,
      default: ''
    },
    iconClass: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    childClass: {
      type: String,
      default: ''
    }
  }
};
</script>

<template>
  <div>
    <div :class="childClass">
      <i
        :class="`${iconClass} ${icon}`"
        aria-hidden="true"
      ></i>
      <span>{{ text }}</span>
    </div>

  </div>
</template>

