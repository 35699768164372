<script>

export default {
  name: "edit-notification",

  props: {
    iconClass: {
      type: String,
      default: ''
    }
  }

};
</script>

<template>
  <div class="d-grid d-md-block d-lg-grid grid_auto_1fr grid-col-gap-1rem text-md-center text-lg-left">
    <div>
      <div
        class="circle w-17 h-17 border shadow d-inline-flex align-items-center justify-content-center mb-md-6 mb-lg-0">
        <i
          class="fas fa-tachometer-alt font-20 c-primary"
          :class="iconClass"
        />
      </div>
    </div>
    <div>
      <h4 class="font-16 font-md-14 font-lg-17 mb-3 c-cloud-burst">
        Quiver Edit Notification
      </h4>
      <p class="c-gray-chateau font-14 font-md-12 font-lg-14 mb-0">
        Quiver edit goes in here. Lorem ipsum dolor sit amet
      </p>
    </div>
  </div>
</template>


